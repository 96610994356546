:root {
  --rs-input-focus-border: var(--primary);
  --rs-state-focus-shadow: var(--primary);
  --rs-picker-value: var(--primary);
  --rs-listbox-option-selected-text: var(--primary);
  --rs-listbox-option-hover-text: var(--primary);
  --rs-bg-active: var(--primary);
  --rs-calendar-cell-selected-hover-bg: var(--primary);
  --rs-picker-count-bg: var(--primary);
  --rs-checkbox-checked-bg: var(--primary);
  --rs-checkbox-border: var(--primary);
  --rs-color-focus-ring: transparent;
  --rs-text-active: var(--primary);
}

.rs-dropdown .rs-dropdown-menu {
  box-shadow: 0 0 00px rgb(0 0 0 / 0%), 0 0px 0px rgb(0 0 0 / 0%) !important;
}

.rdt_TableHead {
  font-family: "PoppinsSemiBold";
}

.rdt_TableBody {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 5px !important;
}
.rdt_Pagination {
  background-color: transparent !important;
}
.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.rs-btn-primary {
  background-color: var(--primary);
}

.rs-picker-tag-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.rs-steps-item-status-process
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color: var(--primary);
  background: var(--primary);
}
.rs-steps-item-status-finish
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
  border-color: var(--primary);
}

.rs-steps-item-status-finish
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon)
  .rs-steps-item-icon::before {
  color: var(--primary);
}

.rs-steps-item-status-finish .rs-steps-item-tail,
.rs-steps-item-status-finish .rs-steps-item-title::after {
  border-color: var(--primary);
}

.rs-dropdown-menu > .rs-dropdown-item > .rs-dropdown-item-content:hover {
  background-color: var(--secondary);
}

.is-checkradio[type="checkbox"] + label {
  padding-top: 1px;
}

.rs-steps-item-title {
  font-weight: bold;
}

.rs-checkbox-wrapper::after {
  left: 0 !important;
}

.rs-toggle-checked .rs-toggle-presentation {
  background-color: var(--primary);
}
.rs-toggle-checked .rs-toggle-presentation:hover {
  background-color: var(--primary);
}

.rs-dropdown-toggle-caret {
  display: none !important;
}
.rs-ripple-pond {
  display: none !important;
}

.rs-dropdown-menu {
  background-color: transparent !important;
}

.rs-auto-complete > .rs-input {
  border: 0px;
}

.cvPDdw {
  position: static !important;
}

.iQTtKS {
  position: static !important;
}

.fuLPYh {
  position: static !important;
}

.RJkoq {
  position: static !important;
}

.iMegPY {
  position: static !important;
}

.rdt_Table {
  position: static !important;
}
