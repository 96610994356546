.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open{
    opacity: 1;
    z-index: 5;
  }
  
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }