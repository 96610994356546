.navbar-burger.right {
  margin-right: auto;
  margin-left: 0;
}

.navbar-item:active {
  text-decoration: none !important;
}
.navbar-item:hover {
  text-decoration: none !important;
}

.navbar.is-link .navbar-brand > a.navbar-item:focus,
.navbar.is-link .navbar-brand > a.navbar-item:hover,
.navbar.is-link .navbar-brand > a.navbar-item.is-active,
.navbar.is-link .navbar-brand .navbar-link:focus,
.navbar.is-link .navbar-brand .navbar-link:hover,
.navbar.is-link .navbar-brand .navbar-link.is-active {
  background-color: var(--color-secondary);
  color: #fff;
  text-decoration: none !important;
}
@media screen and (min-width: 1024px) {
  .navbar.is-link .navbar-start > .navbar-item,
  .navbar.is-link .navbar-start .navbar-link,
  .navbar.is-link .navbar-end > .navbar-item,
  .navbar.is-link .navbar-end .navbar-link {
    color: #fff;
  }
  .navbar.is-link .navbar-start > a.navbar-item:focus,
  .navbar.is-link .navbar-start > a.navbar-item:hover,
  .navbar.is-link .navbar-start > a.navbar-item.is-active,
  .navbar.is-link .navbar-start .navbar-link:focus,
  .navbar.is-link .navbar-start .navbar-link:hover,
  .navbar.is-link .navbar-start .navbar-link.is-active,
  .navbar.is-link .navbar-end > a.navbar-item:focus,
  .navbar.is-link .navbar-end > a.navbar-item:hover,
  .navbar.is-link .navbar-end > a.navbar-item.is-active,
  .navbar.is-link .navbar-end .navbar-link:focus,
  .navbar.is-link .navbar-end .navbar-link:hover,
  .navbar.is-link .navbar-end .navbar-link.is-active {
    background-color: var(--color-secondary);
    color: #fff;
    text-decoration: none !important;
  }
  .navbar.is-link .navbar-start .navbar-link::after,
  .navbar.is-link .navbar-end .navbar-link::after {
    border-color: #fff;
  }
  .navbar.is-link .navbar-item.has-dropdown:focus .navbar-link,
  .navbar.is-link .navbar-item.has-dropdown:hover .navbar-link,
  .navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: var(--color-secondary);
    color: #fff;
    text-decoration: none !important;
  }
  .navbar.is-link .navbar-dropdown a.navbar-item.is-active {
    background-color: var(--color-secondary);
    color: #fff;
    text-decoration: none !important;
  }
}

.rs-auto-complete-menu {
  z-index: 1081;
}
.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
  height: 35px;
}
