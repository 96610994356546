#authSection {
  background: url(../../public/auth/auth-background.jpg) no-repeat center center fixed;
  background-size: 50% 100%;
}

#authSection2 {
  background: url(../../public/img/background.jpg) no-repeat center center fixed;
  background-size: 100% 100%;

}
