@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #24335d;
  --light: #52bdc8;
  --dark: #eb477d;
  --secondary: #eeb9d1;
  --gray-light: #acb2b8;
  --neutral-medium: #808485;
  --neutral-lighter: #d3d7da;
  --neutral-dark: #495056;
}

@font-face {
  font-family: "PoppinsRegular";
  src: url(fonts/Poppins/Poppins-Regular.ttf) format("truetype");
  font-weight: 400 !important;
}

@font-face {
  font-family: "PoppinsMedium";
  src: url(fonts/Poppins/Poppins-Medium.ttf) format("truetype");
  font-weight: 400 !important;
}

@font-face {
  font-family: "PoppinsItalic";
  src: url(fonts/Poppins/Poppins-Italic.ttf) format("truetype");
  font-weight: 400 !important;
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: url("fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 500 !important;
}

@font-face {
  font-family: "PoppinsBold";
  src: url("fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700 !important;
}
@font-face {
  font-family: "PoppinsExtraBold";
  src: url("fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800 !important;
}

@layer base {
  html {
    background-color: #f5f5f5 !important;
  }
}

body {
  background-color: #f5f5f5 !important;
  font-family: "PoppinsRegular", "PoppinsMedium", "PoppinsSemiBold",
    "PoppinsBold", "PoppinsItalic";
}
nav {
  background-color: #f5f5f5 !important;
}

a:link {
  text-decoration: none !important;
}

::selection {
  background-color: var(--light);
  color: white !important;
}

.font-normal {
  font-family: "PoppinsRegular" !important;
}

.font-medium {
  font-family: "PoppinsMedium" !important;
}

.font-italic {
  font-family: "PoppinsItalic" !important;
}

.font-semibold {
  font-family: "PoppinsSemiBold" !important;
}

.font-extrabold {
  font-family: "PoppinsExtraBold" !important;
}
